// custom typefaces
import React from "react";
import "typeface-montserrat";
import "typeface-merriweather";
import "typeface-nunito";
import "typeface-alegreya";
import "typeface-poppins";
import "typeface-cormorant-garamond";
import "typeface-quattrocento";
import "typeface-libre-baskerville";
import { StoreProvider } from "./src/context/store";

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);
