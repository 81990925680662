export default {
  services: {
    en: "Services",
    es: "Servicios",
  },
  contact_us: {
    en: "Contact Us",
    es: "Contáctenos",
  },
  about: {
    en: "About",
    es: "Sobre Nosotros",
  },
  what_our_clients_say: {
    en: "What Our Clients Say",
    es: "Lo Que Dicen Nuestros Clientes",
  },
  our_services: {
    en: "Our Services",
    es: "Nuestro Servicios",
  },
  see_more: {
    en: "Read More",
    es: "Leer Más",
  },
  meet_out_team: {
    en: "Meet Our Team",
    es: "Conozca a Nuestro Equipo",
  },
  open: {
    en: "Open",
    es: "Abierto",
  },
  phone: {
    en: "Phone",
    es: "Teléfono",
  },
  email: {
    en: "Email",
    es: "Email",
  },
  address: {
    en: "Address",
    es: "Dirección",
  },
  get_in_touch: {
    en: "Get In Touch",
    es: "Contáctenos",
  },
  days_open: {
    en: "Monday - Friday",
    es: "Lunes - Viernes",
  },
  weekends_open: {
    en: "Saturday with appointment",
    es: "Sábado con cita",
  },
  outside_normal_business_hours: {
    en:
      "If you are unable to visit us during our office hours, please call us and we will do our best to accommodate you.",
    es:
      "Si no puede visitarnos durante nuestro horario de oficina, llámenos y haremos todo lo posible para atenderte.",
  },
  call_or_visit: {
    en: "Call or Visit Us",
    es: "Llámanos o Visítanos",
  },
};
