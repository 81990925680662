import React from "react";
import { createContext, useContext, useState } from "react";
import translations from "../data/translations";

export enum LANGUAGE {
  es = "es",
  en = "en",
}
const defaultState = {
  language: LANGUAGE.es,
  toggleLanguage: () => {},
  translations: getTranslations(LANGUAGE.es),
};
function getTranslations(language: LANGUAGE) {
  return Object.fromEntries(
    Object.entries(translations).map(([key, values]) => [key, values[language]])
  );
}

const StoreContext = createContext(defaultState);

export const StoreProvider = ({ children }) => {
  const [language, setLanguage] = useState<LANGUAGE>(defaultState.language);
  const [translations, setTranslations] = useState(defaultState.translations);

  const toggleLanguage = () => {
    if (language === LANGUAGE.en) {
      setLanguage(LANGUAGE.es);
      setTranslations(getTranslations(LANGUAGE.es));
    } else {
      setLanguage(LANGUAGE.en);
      setTranslations(getTranslations(LANGUAGE.en));
    }
  };

  const values = { language, toggleLanguage, translations };

  return (
    <StoreContext.Provider value={values}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined || context === null) {
    throw new Error(`useStore must be called within StoreProvider`);
  }
  return context;
};
